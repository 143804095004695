.date-filter {
  position: absolute;
  top: 0;
  left: 160px;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.selection-filter-parent {
  position: absolute;
  top: 130px;
  left: 150px;
  width: 175px;
  height: 64px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-outfit);
}

.date-filter-status {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.4px;
  font-weight: 500;
}

.date-filter-upload-file-type{
  position: absolute;
  top: 0;
  left: 320px;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.status-div{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: Outfit; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  padding-bottom: 10px;
}
.selectstyle{
  color: rgba(0, 0, 0, 0.60);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  width: 123px;
  height: 28px;
  background: #F1F1F1;
  border-radius: 4px;
  border: none;
}
.select-select-div {
  border-radius: 4px;
  background: #F1F1F1;
  width: 123px;
  height: 28px;
}