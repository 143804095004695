.home-child-bulk {
    position: absolute;
    top: 130px;
    left: 150px;
    border-radius: var(--br-xs);
    background-color: #fafafa;
    width: 1538px;
    height: 882px;
  }
  .group-child-bulk {
    background-color: var(--color-white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 1728px;
    height: 100px;
  }
  .group-child-bulk,
  .group-icon,
  .group-item {
    position: absolute;
    top: 0;
    left: 0;
  }
  .group-icon {
    width: 80px;
    height: 48px;
    object-fit: cover;
  }
  .group-item {
    border-radius: 22px;
    /* background-color: #f8f8f8; */
    width: 82px;
    height: 44px;
  }
  .vector-icon {
    position: absolute;
    top: 10px;
    left: 33px;
    width: 9px;
    height: 4.5px;
    object-fit: cover;
  }
  .en,
  .vector-parent {
    position: absolute;
    top: 0;
    left: 0;
  }
  .vector-parent {
    top: 10px;
    left: 20px;
    width: 42px;
    height: 23px;
  }
  .group-group,
  .rectangle-group {
    position: absolute;
    top: 2px;
    left: 1319px;
    width: 82px;
    height: 44px;
  }
  .group-group {
    top: 0;
    left: 0;
    width: 1401px;
    height: 48px;
  }
  .trackstylishopcom {
    position: absolute;
    top: 11px;
    left: 0;
    letter-spacing: 0.4px;
    line-height: 18px;
  }
  .group-inner {
    position: absolute;
    top: 0;
    left: 146px;
    border-radius: 50%;
    width: 41px;
    height: 41px;
    object-fit: cover;
  }
  .trackstylishopcom-parent {
    position: absolute;
    top: 3px;
    left: 1461px;
    width: 187px;
    height: 41px;
    text-align: center;
    font-size: var(--font-size-sm);
    color: rgba(0, 0, 0, 0.6);
  }
  .group-parent-bulk {
    position: absolute;
    top: 26px;
    left: 40px;
    width: 1648px;
    height: 48px;
  }
  .rectangle-parent-bulk {
    position: absolute;
    top: 0;
    left: 0;
    width: 1728px;
    height: 100px;
    text-align: left;
    font-size: 18px;
    color: #333;
  }
  .home-inner-bulk,
  .home-item-bulk {
    position: absolute;
    left: 0;
    background-color: var(--color-white);
    width: 120px;
  }
  .home-item-bulk {
    top: 100px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 942px;
  }
  .home-inner-bulk {
    top: 218px;
    border-bottom: 1px solid var(--color-gray-100);
    box-sizing: border-box;
    height: 118px;
  }
  .rectangle-div-bulk {
    position: absolute;
    top: 232px;
    left: 0;
    border-radius: 9px;
    background-color: var(--color-mediumslateblue);
    width: 6px;
    height: 90px;
  }
  .group-child1-bulk {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-100);
    box-sizing: border-box;
    width: 120px;
    height: 118px;
  }
  .dashboard-bulk {
    position: absolute;
    top: 38px;
    left: 0;
    letter-spacing: 0.4px;
    line-height: 18px;
    font-weight: 500;
  }
  .group-child2-bulk {
    position: absolute;
    top: 0;
    left: 21px;
    width: 36px;
    height: 36px;
    object-fit: cover;
  }
  .dashboard-parent-bulk {
    position: absolute;
    top: 31px;
    left: 23px;
    width: 74px;
    height: 56px;
  }
  .bulk-upload-bulk,
  .rectangle-container-bulk {
    position: absolute;
    font-size: var(--font-size-sm);
  }
  .rectangle-container-bulk {
    top: 100px;
    left: 0;
    width: 120px;
    height: 118px;
  }
  .bulk-upload-bulk {
    top: 287px;
    left: 20px;
    letter-spacing: 0.4px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-mediumslateblue);
  }
  .vector-icon1-bulk {
    position: absolute;
    top: 258.2px;
    left: 48px;
    width: 22px;
    height: 24.3px;
    object-fit: cover;
  }
  .home-child1-bulk {
    position: absolute;
    top: 306px;
    left: 429px;
    box-sizing: border-box;
    width: 300px;
    height: 196px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
  }
  .sample-bulk {
    position: absolute;
    top: 440px;
    left: 528px;
    font-weight: 600;
  }
  .home-child2-bulk {
    position: absolute;
    top: 306px;
    left: 1109px;
    box-sizing: border-box;
    width: 300px;
    height: 196px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
  }
  .delivery-bulk {
    position: absolute;
    top: 440px;
    left: 1223px;
    font-weight: 600;
  }
  .home-child3-bulk {
    position: absolute;
    top: 306px;
    left: 769px;
    box-sizing: border-box;
    width: 300px;
    height: 196px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
  }
  .production-bulk {
    position: absolute;
    top: 440px;
    left: 865px;
    font-weight: 600;
  }
  .please-choose-the-bulk {
    position: absolute;
    top: 207px;
    left: 773px;
    font-size: var(--component-heading-large-lg-size);
    line-height: 120%;
    font-family: var(--component-heading-large-lg);
    color: var(--gray-700);
    text-align: center;
  }
  .box-2-1-bulk,
  .garment-1-icon-bulk,
  .scissors-1-1-bulk {
    position: absolute;
    top: 340px;
    left: 1227px;
    width: 64px;
    height: 64px;
    overflow: hidden;
    object-fit: cover;
  }
  .garment-1-icon-bulk,
  .scissors-1-1-bulk {
    left: 548px;
  }
  .garment-1-icon-bulk {
    left: 887px;
  }
  .home-bulk {
    background-color: var(--color-white);
    width: 1728px;
    height: 1042px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-3xl);
    color: var(--color-black);
    font-family: var(--font-outfit);
  }
  