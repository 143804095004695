.selection-filter-child {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-whitesmoke-200);
  width: 175px;
  height: 38px;
  z-index: 0;
}
.select {
  position: relative;
  letter-spacing: 0.4px;
  display: inline-block;
  width: 133px;
  flex-shrink: 0;
}
.vector-icon {
  position: relative;
  width: 11px;
  height: 6px;
  object-fit: cover;
}
.select-parent {
  margin: 0 !important;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 155px;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}
.selection-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
  font-family: var(--font-outfit);
}
