.fileuploadhistory-child,
.fileuploadhistory-item,
.group-child {
  position: absolute;
  left: 0;
  background-color: var(--color-white);
  width: 120px;
}
.fileuploadhistory-child {
  top: 100px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: 988px;
}
.fileuploadhistory-item,
.group-child {
  top: 218px;
  border-bottom: 1px solid var(--color-gray-100);
  box-sizing: border-box;
  height: 162px;
}
.group-child {
  top: -17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}
.dashboard {
  position: absolute;
  top: 39px;
  left: 15px;
  letter-spacing: 0.4px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.4px;
}
.group-item {
  position: absolute;
  top: 0;
  left: 21px;
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.dashboard-parent {
  position: absolute;
  top: 24px;
  left: 23px;
  width: 74px;
  height: 56px;
}
.rectangle-parent {
  position: absolute;
  top: 100px;
  left: 0;
  width: 120px;
  height: 118px;
  color: var(--color-purple);
}
.bulk-upload {
  position: absolute;
  top: 28.8px;
  left: 0;
  letter-spacing: 0.4px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.4px;
}
.vector-icon1 {
  position: absolute;
  top: 0;
  left: 28px;
  width: 22px;
  height: 24.3px;
  object-fit: cover;
}
.bulk-upload-parent {
  position: absolute;
  top: 234px;
  left: 20px;
  width: 79px;
  height: 46.8px;
}
.fileuploadhistory-inner {
  position: absolute;
  top: 114px;
  left: 0;
  border-radius: 9px;
  background-color: var(--color-purple);
  width: 6px;
  height: 90px;
}
.group-inner,
.rectangle-div {
  position: absolute;
  top: 0;
  left: 0;
}
.group-inner {
  border-radius: 10px;
  background-color: #fafafa;
  width: 1538px;
  height: 851px;
}
.rectangle-div {
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--color-white);
  /* width: 1468px; */
  height: 739px;
}
.group-child1,
.group-child2,
.group-child3,
.group-child4,
.group-child5 {
  position: absolute;
  top: 57px;
  left: 1169px;
  background-color: var(--color-seagreen-200);
  width: 299px;
  height: 62px;
}
.group-child2,
.group-child3,
.group-child4,
.group-child5 {
  top: 244px;
  height: 61px;
}
.group-child3,
.group-child4,
.group-child5 {
  top: 430px;
  height: 62px;
}
.group-child4,
.group-child5 {
  top: 120px;
  background-color: var(--color-crimson-200);
  height: 61px;
}
.group-child5 {
  top: 182px;
  background-color: var(--color-goldenrod-200);
}
.group-child10,
.group-child11,
.group-child12,
.group-child6,
.group-child7,
.group-child8,
.group-child9 {
  position: absolute;
  top: 368px;
  left: 1169px;
  background-color: var(--color-goldenrod-200);
  width: 299px;
  height: 61px;
}
.group-child10,
.group-child11,
.group-child12,
.group-child7,
.group-child8,
.group-child9 {
  top: 555px;
}
.group-child10,
.group-child11,
.group-child12,
.group-child8,
.group-child9 {
  top: 616px;
}
.group-child10,
.group-child11,
.group-child12,
.group-child9 {
  top: 678px;
}
.group-child10,
.group-child11,
.group-child12 {
  top: 306px;
  background-color: var(--color-crimson-200);
}
.group-child11,
.group-child12 {
  top: 493px;
}
.group-child12 {
  top: 0;
  left: 0;
  border-radius: var(--br-7xs) var(--br-7xs) 0 0;
  background-color: var(--color-whitesmoke-200);
  width: 1468px;
  height: 57px;
}
.group-child13,
.line-div {
  position: absolute;
  top: 118.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 1469px;
  height: 1px;
}
.group-child13 {
  top: 304.5px;
}
.group-child14,
.group-child15,
.group-child16,
.group-child17,
.group-child18,
.group-child19,
.group-child20,
.group-child21 {
  position: absolute;
  top: 491.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 1469px;
  height: 1px;
}
.group-child15,
.group-child16,
.group-child17,
.group-child18,
.group-child19,
.group-child20,
.group-child21 {
  top: 180.5px;
}
.group-child16,
.group-child17,
.group-child18,
.group-child19,
.group-child20,
.group-child21 {
  top: 366.5px;
}
.group-child17,
.group-child18,
.group-child19,
.group-child20,
.group-child21 {
  top: 553.5px;
}
.group-child18,
.group-child19,
.group-child20,
.group-child21 {
  top: 614.5px;
}
.group-child19,
.group-child20,
.group-child21 {
  top: 676.5px;
}
.group-child20,
.group-child21 {
  top: 242.5px;
}
.group-child21 {
  top: 428.5px;
}
.eta-bulkuploadcsv {
  position: absolute;
  top: 79px;
  left: 750px;
  text-decoration: underline;
  line-height: 130%;
  font-weight: 600;
  color: var(--color-goldenrod-100);
}
.eta-bulkuploadcsv1,
.eta-bulkuploadcsv2,
.eta-bulkuploadcsv3,
.eta-bulkuploadcsv4,
.eta-bulkuploadcsv5,
.eta-bulkuploadcsv6,
.eta-bulkuploadcsv7 {
  position: absolute;
  top: 265px;
  left: 750px;
  text-decoration: underline;
  line-height: 130%;
  font-weight: 600;
  color: var(--color-goldenrod-100);
}
.eta-bulkuploadcsv2,
.eta-bulkuploadcsv3,
.eta-bulkuploadcsv4,
.eta-bulkuploadcsv5,
.eta-bulkuploadcsv6,
.eta-bulkuploadcsv7 {
  top: 452px;
}
.eta-bulkuploadcsv3,
.eta-bulkuploadcsv4,
.eta-bulkuploadcsv5,
.eta-bulkuploadcsv6,
.eta-bulkuploadcsv7 {
  top: 141px;
}
.eta-bulkuploadcsv4,
.eta-bulkuploadcsv5,
.eta-bulkuploadcsv6,
.eta-bulkuploadcsv7 {
  top: 327px;
}
.eta-bulkuploadcsv5,
.eta-bulkuploadcsv6,
.eta-bulkuploadcsv7 {
  top: 514px;
}
.eta-bulkuploadcsv6,
.eta-bulkuploadcsv7 {
  top: 203px;
}
.eta-bulkuploadcsv7 {
  top: 389px;
}
.eta-bulkuploadcsv8 {
  position: absolute;
  top: 576px;
  line-height: 130%;
}
.eta-bulkuploadcsv10,
.eta-bulkuploadcsv8,
.eta-bulkuploadcsv9 {
  left: 750px;
  text-decoration: underline;
  font-weight: 600;
  color: var(--color-goldenrod-100);
}
.eta-bulkuploadcsv9 {
  position: absolute;
  top: 637px;
  line-height: 130%;
}
.eta-bulkuploadcsv10 {
  top: 699px;
}
.eta-bulkuploadcsv10,
.file-name,
.jacky-landmark {
  position: absolute;
  line-height: 130%;
}
.file-name {
  top: 20px;
  left: 780px;
  color: var(--color-gray-200);
}
.jacky-landmark {
  top: 79px;
  left: 987px;
  font-weight: 600;
}
.jacky-landmark1,
.jacky-landmark2,
.kiran-landmark,
.leo-landmark {
  position: absolute;
  top: 265px;
  left: 987px;
  line-height: 130%;
  font-weight: 600;
}
.jacky-landmark1,
.jacky-landmark2,
.leo-landmark {
  top: 452px;
}
.jacky-landmark2,
.leo-landmark {
  top: 141px;
  left: 993px;
}
.jacky-landmark2 {
  top: 327px;
  left: 987px;
}
.aruna-landmark,
.jacky-landmark3,
.jacky-landmark4,
.leo-landmark1 {
  position: absolute;
  top: 514px;
  left: 987px;
  line-height: 130%;
  font-weight: 600;
}
.aruna-landmark,
.jacky-landmark4,
.leo-landmark1 {
  top: 203px;
}
.aruna-landmark,
.leo-landmark1 {
  top: 389px;
  left: 985px;
}
.leo-landmark1 {
  top: 576px;
  left: 993px;
}
.leo-landmark2 {
  top: 637px;
  left: 993px;
  font-weight: 600;
}
.leo-landmark2,
.leo-landmark3,
.uploaded-by {
  position: absolute;
  line-height: 130%;
}
.leo-landmark3 {
  top: 699px;
  left: 993px;
  font-weight: 600;
}
.uploaded-by {
  top: 20px;
  left: 1000px;
  color: var(--color-gray-200);
}
.oct-5-2023,
.pre-production {
  position: absolute;
  top: 79px;
  left: 115px;
  line-height: 130%;
  font-weight: 600;
}
.pre-production {
  left: 472px;
}
.post-production,
.production,
.sep-10-2023,
.sep-25-2023 {
  position: absolute;
  top: 265px;
  left: 111px;
  line-height: 130%;
  font-weight: 600;
}
.post-production,
.production,
.sep-10-2023 {
  left: 469px;
}
.production,
.sep-10-2023 {
  top: 452px;
  left: 109px;
}
.production {
  left: 489px;
}
.oct-1-2023,
.post-production1,
.production1,
.sep-20-2023 {
  position: absolute;
  top: 141px;
  left: 115px;
  line-height: 130%;
  font-weight: 600;
}
.post-production1,
.production1,
.sep-20-2023 {
  left: 469px;
}
.production1,
.sep-20-2023 {
  top: 327px;
  left: 108px;
}
.production1 {
  left: 489px;
}
.pre-production1,
.production2,
.sep-30-2023,
.sep-5-5 {
  position: absolute;
  top: 514px;
  left: 108px;
  line-height: 130%;
  font-weight: 600;
}
.pre-production1,
.production2,
.sep-30-2023 {
  left: 472px;
}
.production2,
.sep-30-2023 {
  top: 203px;
  left: 110px;
}
.production2 {
  left: 489px;
}
.aug-30-2023,
.post-production2,
.production3,
.sep-15-2023 {
  position: absolute;
  top: 389px;
  left: 110px;
  line-height: 130%;
  font-weight: 600;
}
.aug-30-2023,
.post-production2,
.production3 {
  left: 469px;
}
.aug-30-2023,
.production3 {
  top: 576px;
  left: 109px;
}
.production3 {
  left: 489px;
}
.aug-30-20231,
.aug-30-20232,
.pre-production2,
.production4 {
  position: absolute;
  top: 637px;
  left: 109px;
  line-height: 130%;
  font-weight: 600;
}
.aug-30-20232,
.pre-production2,
.production4 {
  left: 472px;
}
.aug-30-20232,
.production4 {
  top: 699px;
  left: 109px;
}
.production4 {
  left: 489px;
}
.upload-file-type,
.uploaded-at {
  position: absolute;
  top: 20px;
  left: 150px;
  line-height: 130%;
  color: var(--color-gray-200);
}
.upload-file-type {
  left: 474px;
}
.failed,
.successful,
.successful1,
.successful2 {
  position: absolute;
  top: 79px;
  left: 1285px;
  line-height: 130%;
  font-weight: 600;
  color: var(--color-seagreen-100);
}
.failed,
.successful1,
.successful2 {
  top: 265px;
  left: 1289px;
}
.failed,
.successful2 {
  top: 452px;
}
.failed {
  top: 130px;
  left: 1299px;
  color: var(--color-crimson-100);
}
.invalid-lines1 {
  color: var(--color-gray-200);
}
.invalid-lines,
.view-details {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 130%;
}
.view-details {
  left: 122px;
  text-decoration: underline;
  font-weight: 500;
  color: var(--color-black);
}
.invalid-lines-container,
.invalid-lines-group,
.invalid-lines-parent {
  position: absolute;
  top: 155px;
  left: 1224px;
  width: 189px;
  height: 16px;
  font-size: var(--font-size-xs);
  color: var(--color-crimson-100);
}
.invalid-lines-container,
.invalid-lines-group {
  top: 341px;
}
.invalid-lines-container {
  top: 528px;
}
.failed1,
.failed2 {
  left: 1302px;
  font-weight: 600;
  color: var(--color-crimson-100);
}
.failed1 {
  position: absolute;
  top: 316px;
  line-height: 130%;
}
.failed2 {
  top: 503px;
}
.failed2,
.running,
.running1 {
  position: absolute;
  line-height: 130%;
}
.running {
  top: 203px;
  left: 1296px;
  font-weight: 600;
  color: var(--color-goldenrod-100);
}
.running1 {
  top: 389px;
}
.running1,
.running2,
.running3 {
  left: 1296px;
  font-weight: 600;
  color: var(--color-goldenrod-100);
}
.running2 {
  position: absolute;
  top: 576px;
  line-height: 130%;
}
.running3 {
  top: 637px;
}
.running3,
.running4,
.status {
  position: absolute;
  line-height: 130%;
}
.running4 {
  top: 699px;
  left: 1296px;
  font-weight: 600;
  color: var(--color-goldenrod-100);
}
.status {
  top: 20px;
  left: 1298px;
  color: var(--color-gray-200);
}
.group-icon {
  position: absolute;
  top: 57px;
  left: 921px;
  width: 0;
  height: 682px;
  object-fit: cover;
}
.group-child22,
.group-child23,
.group-child24 {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  border-right: 1px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 1px;
  height: 63px;
}
.group-child23,
.group-child24 {
  top: 61.5px;
}
.group-child24 {
  top: 123.5px;
}
.line-group,
.line-parent {
  position: absolute;
  top: 57px;
  left: 1168px;
  width: 0;
  height: 186px;
}
.line-group {
  top: 243px;
}
.group-child31,
.group-child32 {
  position: absolute;
  top: 184.5px;
  left: -0.5px;
  border-right: 1px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 1px;
  height: 63px;
}
.group-child32 {
  top: 246.5px;
}
.group-child33,
.line-container {
  position: absolute;
  top: 430px;
  left: 1168px;
  width: 0;
  height: 309px;
}
.group-child33 {
  top: 57px;
  left: 345px;
  width: 344px;
  height: 682px;
  object-fit: cover;
}
.group-child34 {
  position: absolute;
  top: 45px;
  left: 0px;
  border-radius: 12px 12px 0px 0px;
  background-color: #9747ff;
  width: 1468px;
  height: 6px;
}
.rectangle-container,
.rectangle-group {
  position: absolute;
  top: -6px;
  left: 35px;
  width: 1468px;
  height: 790px;
  overflow: hidden;
}
.rectangle-group {
  top: 214px;
  left: 150px;
  width: 1538px;
  height: 851px;
}
.fileuploadhistory {
  position: absolute;
  background-color: var(--color-white);
  width: 100%;
  height: 100vh;
  overflow: auto;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-outfit);
}
/* New code start */
.success {
  background: #11653033;
  width: 299px;
  height: 62px;
  flex-shrink: 0;
  padding: 21px;
  color: #116530;
}
.failedcustm {
  background: #DD202033;
  width: 299px;
  height: 62px;
  flex-shrink: 0;
}
.table-header {
  background: #b71a1a;
}
.tableClass {
  width: 1468px;
  height: 739px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  background: #FFF;
  /* font-family: Outfit; */
}
.tableheader {
  width: 1468px;
  height: 57px;
  flex-shrink: 0;
  border-radius: 6px 6px 0px 0px;
  background: #F1F1F1;
  color: #00000099;
  text-align: center;
  /* font-family: Outfit; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}
.bold-weight {
  font-weight: bold;
}
.colorYellow{
  color: #E1A239;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  text-decoration-line: underline;
}
body{
  font-family: "Nunito",sans-serif;
}
.historypage {
  position: absolute;
  top: 0;
  left: 37px;
  width: 79px;
  height: 46.8px;
}
.historyclass {
  position: absolute;
  top: 28.8px;
  left: 0;
  letter-spacing: 0.4px;
  line-height: 18px;
  font-weight: 500;
}
.spinner-border {
  margin-top: 15px;
}
/* New code end */
